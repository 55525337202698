import React, { Fragment } from 'react';
import config from '../config/config.production';
import {
  CreateCropConclReportIcon,
  GlobalTraitsViewIcon,
  MyTrialsIcon,
  PowerBiReportingIcon,
  TrialAppIcon,
  TrialPrepPlanningIcon,
  DataImportIcon
} from '../assets/svgs';
import { Route, Link, useLocation } from 'react-router-dom';

import trialReportIcon from '../assets/images/trialreport.png';
import trialPrepIcon from '../assets/images/trialprepicon.png';
import trialObservationIcon from '../assets/images/trialobservationicon.png';

export const TDMProgrammes = (props: any) => {
  const getOperatingSystem = () => {
    let operatingSystem: any = 'Not known';
    if (window.navigator.userAgent.indexOf('Windows') !== -1) {
      operatingSystem = `Windows`;
    }
    if (window.navigator.userAgent.indexOf('iPhone') !== -1) {
      operatingSystem = `Iphone`;
    }
    if (window.navigator.userAgent.indexOf('iPad') !== -1) {
      operatingSystem = `Ipad`;
    }
    if (window.navigator.userAgent.indexOf('iPad') !== -1) {
      operatingSystem = `Ipad`;
    }
    if (window.navigator.userAgent.indexOf('Android ') !== -1) {
      operatingSystem = `Android`;
    }
    return operatingSystem;
  };

  const handleWindowsClick = () => {
    var frame: any = document.createElement('iframe');
    try {
      // window.open(`${config.desktopProtocol}`)
      window.open(`${config.desktopProtocol}`, '_self');
      // window.location.replace(
      //     `${config.desktopProtocol}`
      // );
    } catch (e: any) {
      if (e.name == 'NS_ERROR_UNKNOWN_PROTOCOL') window.open(`${config.desktopProtocol}`, '_self');
      // window.location.replace(
      //     `${config.desktopProtocol}`
      // );
    }
  };

  const handleAndroidClick = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) return;
      window.location.replace(`${config.androidPlayStore}`);
    }, 250);
    window.location.replace(`${config.androidProtocol}`);
  };

  const handleIphoneClick = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) return;
      window.location.replace(`${config.iosPlayStore}`);
    }, 250);
    window.location.replace(`${config.iosAppProtocol}`);

    // #method1
    // window.location.replace("instagram://");
    // setTimeout(() => {
    //     window.location.replace(
    //         "https://apps.apple.com/us/app/instagram/id389801252"
    //     );
    // }, 100);

    // var frame = document.createElement('iframe');
    // frame.src = 'chrome://';
    //
    // frame.style.display = 'none';
    // document.body.appendChild(frame);

    // // the following is optional, just to avoid an unnecessary iframe on the page
    //     setTimeout(function() { document.body.removeChild(frame); }, 4);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <h2 className="main-title">Your TDM Programmes / favourites</h2>
        </div>
      </div>

      <div className="row">
        <div className="p-2 col-sm-6 col-md-4 col-xl-2 no-line">
          <a href={`${config.trialPrepUrl}`} target="_self">
            <div className="w-auto card h-100 text-center widget-fav">
              <div className="card-body pt-4">
                {/*<a href ='vscode://' >*/}
                {/* <TrialPrepPlanningIcon /> */}
                <img src={trialPrepIcon} alt="logo" style={{ height: '60px' }} />
                <h6 className="card-title fw-normal mt-3 text-truncate">TrialPrep</h6>
              </div>
            </div>
          </a>
        </div>

        <div
          className="p-2 col-sm-6 col-md-4 col-xl-2 no-line"
          style={{ display: config.trialAppUrl.trim().length <= 0 ? 'none' : '' }}>
          <a href={`${config.trialAppUrl}`} target="_self">
            <div className="w-auto card h-100 text-center widget-fav">
              <div className="card-body pt-4">
                {/* <TrialAppIcon /> */}
                <img src={trialObservationIcon} alt="logo" style={{ height: '60px' }} />
                <h6 className="card-title fw-normal mt-3 text-truncate">TrialApp</h6>
              </div>
            </div>
          </a>
        </div>

        {/* <div className='p-2 col-sm-6 col-md-4 col-xl-2'>
                    <div className="w-auto card h-100 text-center widget-fav">
                        {getOperatingSystem() === `Windows` && (
                            <div className="card-body pt-4">
                                <div onClick={handleWindowsClick} style={{'cursor': 'pointer'}}>
                                    <TrialAppIcon/>
                                </div>
                                <h6 className='card-title fw-normal mt-3 text-truncate'>Trial App</h6>
                            </div>
                        )}
                        {getOperatingSystem() === `Iphone` && (
                            <div className="card-body pt-4">
                                <div onClick={handleIphoneClick} style={{'cursor': 'pointer'}}>
                                    <TrialAppIcon/>
                                </div>
                                <h6 className='card-title fw-normal mt-3 text-truncate'>Trial App</h6>
                            </div>
                        )}
                        {getOperatingSystem() === `Android` && (
                            <div className="card-body pt-4">
                                <div onClick={handleAndroidClick}>
                                    <TrialAppIcon/>
                                </div>
                                <h6 className='card-title fw-normal mt-3 text-truncate'>Trial App</h6>
                            </div>
                        )}
                        {getOperatingSystem() === `Not known` && (
                            <div className="card-body pt-4">
                                <div style={{'cursor': 'pointer'}}>
                                    <TrialAppIcon onClick={handleWindowsClick}/>
                                </div>
                                <h6 className='card-title fw-normal mt-3 text-truncate'>Trial App</h6>
                            </div>
                        )}

                
                    </div>
                </div> */}

        <div className="p-2 col-sm-6 col-md-4 col-xl-2 no-line">
          <a href={`${config.powerBiUrl}`} target="_blank">
            <div className="w-auto card h-100 text-center widget-fav">
              <div className="card-body pt-4">
                <img src={trialReportIcon} alt="logo" style={{ height: '60px', width: '60px' }} />
                <h6 className="card-title fw-normal mt-3 text-truncate">TDM BI Report</h6>
              </div>
            </div>
          </a>
        </div>

        <div className="p-2 col-sm-6 col-md-4 col-xl-2 no-line">
          <Link to={`/dataimport`} target="_blank">
            <div className="w-auto card h-100 text-center widget-fav">
              <div className="card-body pt-4">
                <DataImportIcon />
                <h6 className="card-title fw-normal mt-3 text-truncate">Data Import</h6>
              </div>
            </div>
          </Link>
        </div>

        {/*<div className='p-2 col-sm-6 col-md-4 col-xl-2'>*/}
        {/*    <div className="w-auto card h-100 text-center widget-fav">*/}
        {/*        <div className="card-body pt-4">*/}
        {/*            <a href={`${config.globalTraitUrl}`} target="_blank">*/}
        {/*                <GlobalTraitsViewIcon/>*/}
        {/*            </a>*/}
        {/*            <h6 className='card-title fw-normal mt-3 text-truncate'>Global traits</h6>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    </Fragment>
  );
};

export default TDMProgrammes;
